var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "ul",
      { staticClass: "ques wrap" },
      _vm._l(_vm.questionModels, function (item, index) {
        return _c(
          "li",
          { key: index, staticClass: "q-item" },
          [
            _c("span", { staticClass: "topic" }, [
              _vm._v(_vm._s(item.title)),
              _c("i", [
                _vm._v("(" + _vm._s(item.type === "s" ? "单选" : "多选") + ")"),
              ]),
            ]),
            !item.type
              ? _c(
                  "van-checkbox-group",
                  {
                    on: { change: _vm.onChangeMultiple },
                    model: {
                      value: _vm.qa[item.id],
                      callback: function ($$v) {
                        _vm.$set(_vm.qa, item.id, $$v)
                      },
                      expression: "qa[item.id]",
                    },
                  },
                  _vm._l(item.ques, function (it, ind) {
                    return _c(
                      "van-grid",
                      {
                        key: ind,
                        attrs: {
                          "column-num": 2,
                          border: false,
                          center: false,
                        },
                      },
                      [
                        _c(
                          "van-grid-item",
                          [
                            _c(
                              "van-checkbox",
                              {
                                attrs: {
                                  disabled: _vm.disabled,
                                  name: ind * 2 + 1,
                                  shape: "square",
                                },
                              },
                              [_vm._v(_vm._s(it[0]))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-grid-item",
                          [
                            it[1]
                              ? _c(
                                  "van-checkbox",
                                  {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      name: (ind + 1) * 2,
                                      shape: "square",
                                    },
                                  },
                                  [_vm._v(_vm._s(it[1]))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c(
                  "van-radio-group",
                  {
                    on: { change: _vm.onChangeMultiple },
                    model: {
                      value: _vm.qa[item.id],
                      callback: function ($$v) {
                        _vm.$set(_vm.qa, item.id, $$v)
                      },
                      expression: "qa[item.id]",
                    },
                  },
                  _vm._l(item.ques, function (it, ind) {
                    return _c(
                      "van-grid",
                      {
                        key: ind,
                        attrs: {
                          "column-num": 2,
                          border: false,
                          center: false,
                        },
                      },
                      [
                        _c(
                          "van-grid-item",
                          [
                            _c(
                              "van-radio",
                              {
                                attrs: {
                                  disabled: _vm.disabled,
                                  name: ind * 2 + 1,
                                  shape: "square",
                                },
                              },
                              [_vm._v(_vm._s(it[0]))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "van-grid-item",
                          [
                            it[1]
                              ? _c(
                                  "van-radio",
                                  {
                                    attrs: {
                                      disabled: _vm.disabled,
                                      name: (ind + 1) * 2,
                                      shape: "square",
                                    },
                                  },
                                  [_vm._v(_vm._s(it[1]))]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips flex f-ai-c" }, [
      _c("span", [
        _vm._v(
          "温馨提示：急性病以当下情况为准，慢性病以近3天症状为准，请认证填写以便医生准确诊疗"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }