/**
 * type: s  单选， 默认为多选
 */
export default [
	{
		id: 'q1',
		title: '1、您的寒热感受如何？',
		ques: [
			['正常', '发热'], ['午后或夜间发热', '怕冷'], ['寒热往来', '怕风'], ['手足心热', '胸腹热'], ['手脚冰凉']
		]
	},
	{
		id: 'q2',
		title: '2、您的出汗情况如何？',
		ques: [
			['正常', '醒时经常汗出、活动后明显'], ['睡时汗出、醒则汗止', '无汗'], ['冷汗', '热汗'], ['头汗', '黄汗'], ['战汗 ', '大汗不止'], ['手足心出汗', '半身汗'], ['心胸汗']
		]
	},
	{
		id: 'q3',
		title: '3、您的身体是否有疼痛？',
		ques: [
			['无疼痛', '头痛'], ['面部疼痛', '胸痛'], ['胁肋痛', '胃脘痛'], ['腹痛', '腰背痛'], ['肩颈痛', '四肢疼痛'], ['周身疼痛']
		]
	},
	{
		id: 'q4',
		title: '4、您的身体除疼痛外，是否有以下不适？',
		ques: [
			['无其他不适', '头晕'], ['眼花', '心慌'], ['胸闷', '胃胀'], ['恶心', '呕吐'], ['干呕', '腹胀'], ['头重如裹', '疲乏无力'], ['四肢麻木']
		]
	},
	{
		id: 'q5',
		title: '5、您的口部是否有不适？',
		ques: [
			['无不适', '口干'], ['口苦', '口黏'], ['口臭', '口淡'], ['口酸', '口咸'], ['口甜']
		]
	},
	{
		id: 'q6',
		title: '6、您的睡眠情况如何？',
		ques: [
			['睡眠质量良好', '不易入睡'], ['睡而易醒', '难以复睡'], ['时时惊醒、睡不安宁', '彻夜不眠'], ['多梦', '精神疲倦、睡意很浓、经常不自主入睡']
		]
	},
	{
		id: 'q7',
		title: '7、您的饮食情况如何？',
		ques: [
			['饮食正常', '食欲减退，厌食'], ['进食量大易饿', '饥不欲食'], ['偏嗜食物']
		]
	},
	{
		id: 'q8',
		title: '8、您的口渴及饮水情况如何？',
		ques: [
			['饮水正常', '口渴'], ['喜饮热水', '喜饮冷水'], ['多饮多尿']
		]
	},
	{
		id: 'q9',
		title: '9、您的大便性状如何？',
		ques: [
			['形状正常', '不成形'], ['水样便', '粘腻'], ['干结如羊屎状', '较多未消化食物']
		]
	},
	{
		id: 'q10',
		title: '10、您的大便颜色如何？',
		ques: [
			['黄色', '黑色'], ['青绿色', '便血'], ['白陶土便', '脓血便']
		]
	},
	{
		id: 'q11',
		title: '11、您的大便频率如何？',
		ques: [
			['每天或隔天1次', '1天多次'], ['便秘']
		]
	},
	{
		id: 'q12',
		title: '12、您的小便频率及尿量如何？',
		ques: [
			['频率正常（5-8次/日）', '次数偏多'], ['次数偏少', '尿量增多'], ['尿量减少', '夜尿频多'], ['小便清长']
		]
	},
	{
		id: 'q13',
		title: '13、您的小便色质如何？',
		ques: [
			['小便正常', '小便清长'], ['小便短黄', '尿中带血'], ['小便混浊', '尿中有砂石 '], ['尿有泡沫']
		]
	}
];